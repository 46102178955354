"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Firstbuy',
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    onLogin: function onLogin() {
      console.log((0, _auth.getToken)());
      window.open("http://poster-design.yunzongbu.cn/home?token=".concat((0, _auth.getToken)()));
    }
  }
};